export default [
  {
    key: 'companyId',
    label: 'field.company',
    type: 'nAsynSingleSelection',
    repository: 'company',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    type: 'nAsynSingleSelection',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'managerId',
    label: 'field.supervisor',
    type: 'nAsynSingleSelection',
    repository: 'employee',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'positionId',
    label: 'field.position',
    type: 'nAsynSingleSelection',
    repository: 'position',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'idCard',
    label: 'field.idCard',
    type: 'text',
    operator: '%',
  },
  {
    key: 'name',
    label: 'field.name',
    type: 'text',
    operator: '%',
  },
];
