var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('n-search-container',{attrs:{"fields":_vm.searchFields},on:{"search":_vm.search,"reset":_vm.reset}},[_c('n-search-input',{ref:"search",attrs:{"fields":_vm.searchFields},scopedSlots:_vm._u([{key:"departmentId",fn:function(item){return [_c('b-form-group',{attrs:{"label-for":item.field.key}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t(item.field.label))+" ")]),_c('n-async-single-select',{ref:item.field.key,attrs:{"query":_vm.params.companyId
                ? [
                    {
                      searchby: 'company_id',
                      searchoperator: '=',
                      search: _vm.params.companyId,
                    } ]
                : [],"name":item.field.key,"repository":item.field.repository,"selection-key":item.field.selectionKey,"selection-label":item.field.selectionLabel},model:{value:(_vm.params[item.field.key]),callback:function ($$v) {_vm.$set(_vm.params, item.field.key, $$v)},expression:"params[item.field.key]"}})],1)]}}]),model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-1"},[_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("general.entries")))]),_c('v-select',{staticClass:"per-page-selector d-inline-block ml-50 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary","to":{ name: ("chart-" + _vm.permissionType) }}},[_vm._v(_vm._s(_vm.$t("button.organizationalChart")))]),_c('b-button',{attrs:{"variant":"primary","to":{ name: ("create-" + _vm.permissionType) }}},[_vm._v(_vm._s(_vm.$t("button.create")))])],1)],1)],1),_c('div',[_c('n-view-table',{ref:"viewTable",attrs:{"fields":_vm.viewTableFields,"type":_vm.permissionType},scopedSlots:_vm._u([{key:"cell(status)",fn:function(data){return [(data.item.isEnable)?_c('b-badge',{attrs:{"pill":"","variant":"success","badge-glow":""}},[_vm._v(" "+_vm._s(_vm.$t("status.active"))+" ")]):_c('b-badge',{attrs:{"pill":"","variant":"danger","badge-glow":""}},[_vm._v(" "+_vm._s(_vm.$t("status.inactive"))+" ")])]}},{key:"cell(company)",fn:function(data){return [(data.item.company)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'view-company',
              params: { id: data.item.company.id },
            }}},[_vm._v(" "+_vm._s(data.item.company.name)+" ")]):_vm._e()]}},{key:"cell(department)",fn:function(data){return [(data.item.department)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'view-department',
              params: { id: data.item.department.id },
            }}},[_vm._v(" "+_vm._s(data.item.department.name)+" ")]):_vm._e()]}},{key:"cell(manager)",fn:function(data){return [(data.item.manager)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'view-employee',
              params: { id: data.item.manager.id },
            }}},[_vm._v(" "+_vm._s(data.item.manager.name)+" ")]):_vm._e()]}},{key:"cell(position)",fn:function(data){return [(data.item.position)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'view-position',
              params: { id: data.item.position.id },
            }}},[_vm._v(" "+_vm._s(data.item.position.name)+" ")]):_vm._e()]}},{key:"cell(lastActivityAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.lastActivityAt))+" ")]}}])}),_c('n-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.loading,"current-page":_vm.params.page,"per-page":_vm.$store.state.pagination.perPage,"total":_vm.total,"type":_vm.permissionType,"view-modal":true},on:{"sort-changed":_vm.sortChanged,"view":_vm.view},scopedSlots:_vm._u([{key:"cell(canReviewRequest)",fn:function(data){return [(data.item.canReviewRequest)?_c('b-badge',{attrs:{"pill":"","variant":"success","badge-glow":""}},[_vm._v(" "+_vm._s(_vm.$t("status.can"))+" ")]):_c('b-badge',{attrs:{"pill":"","variant":"danger","badge-glow":""}},[_vm._v(" "+_vm._s(_vm.$t("status.cannot"))+" ")])]}},{key:"cell(status)",fn:function(data){return [(data.item.isEnable)?_c('b-badge',{attrs:{"pill":"","variant":"success","badge-glow":""}},[_vm._v(" "+_vm._s(_vm.$t("status.active"))+" ")]):_c('b-badge',{attrs:{"pill":"","variant":"danger","badge-glow":""}},[_vm._v(" "+_vm._s(_vm.$t("status.inactive"))+" ")])]}},{key:"cell(company)",fn:function(data){return [(data.item.company)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'view-company',
              params: { id: data.item.company.id },
            }}},[_vm._v(" "+_vm._s(data.item.company.name)+" ")]):_vm._e()]}},{key:"cell(department)",fn:function(data){return [(data.item.department)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'view-department',
              params: { id: data.item.department.id },
            }}},[_vm._v(" "+_vm._s(data.item.department.name)+" ")]):_vm._e()]}},{key:"cell(manager)",fn:function(data){return [(data.item.manager)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'view-employee',
              params: { id: data.item.manager.id },
            }}},[_vm._v(" "+_vm._s(data.item.manager.name)+" ")]):_vm._e()]}},{key:"cell(position)",fn:function(data){return [(data.item.position)?_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'view-position',
              params: { id: data.item.position.id },
            }}},[_vm._v(" "+_vm._s(data.item.position.name)+" ")]):_vm._e()]}},{key:"cell(lastActivityAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(data.item.lastActivityAt))+" ")]}}])}),_c('n-pagination',{ref:"pagination",staticClass:"mt-2",attrs:{"total":_vm.total,"per-page":_vm.$store.state.pagination.perPage,"page":_vm.params.page},on:{"change":_vm.list}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }